<template>
  <div data-testid="progress-bar" class="progress-bar" />
</template>

<!-- TODO: Migrate to <script setup lang="ts"> -->
<!-- eslint-disable vue/component-api-style -->
<!-- eslint-disable vue/block-lang -->
<script>
import nprogress from 'nprogress'
import { mapState } from 'vuex'

export default {
  name: 'PProgressBar',

  computed: {
    ...mapState({
      loading: state => state.global.loading,
    }),
  },

  watch: {
    loading(val) {
      if (val) {
        nprogress.start()
      } else {
        nprogress.done()
      }
    },
  },

  mounted() {
    if (this.loading) {
      nprogress.start()
    }
  },

  created() {
    nprogress.configure({
      parent: '.progress-bar',
      trickleRate: 0.1,
      trickleSpeed: 200,
    })
  },
}
</script>

<style lang="scss" scoped>
.progress-bar {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  z-index: 99999;
  pointer-events: none;

  :deep(#nprogress) {
    .bar {
      background-color: $color-primary-50;
      height: 3px;
    }

    .peg,
    .spinner-icon {
      display: none;
    }
  }
}
</style>
